<template>
  <div class="main">
    <div class="loginDesc">
      <!-- <div class="logo"></div> -->
      <img src="https://static.livemew.com/livemew/image/logo.png" class="logo" alt="" srcset="" />

      <div class="text">让互动更简单</div>
    </div>
    <div class="loginBox">
      <div class="tab-wrap">
        <div class="tab" :class="{ active: lrType === 2 }" @click="changeTab(2)">
          注册
        </div>
        <div class="line"></div>
        <div class="tab" :class="{ active: lrType === 1 }" @click="changeTab(1)">
          登录
        </div>
      </div>
      <el-form ref="form" :model="registerForm" :rules="registerRules" :label-width="'0px'" v-if="lrType === 2">
        <el-form-item prop="phone" class="name-input">
          <el-input v-model="registerForm.phone" placeholder="请输入手机号">
            <div slot="prefix" class="pre">中国 +86</div>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" class="password-input">
          <el-input v-model="registerForm.password" placeholder="请输入密码" type="password"></el-input>
        </el-form-item>
        <el-form-item prop="code" class="code-input">
          <el-row :gutter="40">
            <el-col :span="18">
              <el-input v-model="registerForm.code" type="text" placeholder="请输入验证码"></el-input>
            </el-col>
            <el-col :span="4">
              <el-button type="text" @click="getValidate('1')">
                {{ isCd ? cd : '获取验证码' }}</el-button></el-col>
          </el-row>
        </el-form-item>

        <div class="know">
          阅读并同意
          <el-button type="text" class="text">用户协议</el-button> 、<el-button class="text" type="text">隐私声明</el-button>
        </div>
        <el-form-item class="footer">
          <el-button type="primary" class="submit" @click="handleRegister">立即注册</el-button>
        </el-form-item>
      </el-form>

      <el-form ref="form" :model="loginForm" :rules="loginRules" :label-width="'0px'" v-if="lrType === 1">
        <el-form-item prop="phone" class="name-input">
          <el-input v-model="loginForm.phone" placeholder="请输入手机号">
            <div slot="prefix" class="pre">中国 +86</div>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" class="password-input">
          <el-input v-model="loginForm.password" placeholder="请输入密码" type="password"></el-input>
        </el-form-item>

        <div class="tip-input">
          还没有账号？<el-button type="text" class="text" @click="changeTab(2)">立即注册</el-button>
        </div>

        <div class="know">
          阅读并同意
          <el-button type="text" class="text">用户协议</el-button> 、<el-button class="text" type="text">隐私声明</el-button>
        </div>
        <el-form-item class="footer">
          <el-button type="primary" class="submit" @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  // getQrcode,
  // getLoginStatus,
  // scanLogin,
  inputLogin,
  register,
  sendCode,
  resetPassword,
} from '@/api/index';
import { timeoutTask } from '@/utils/index';
import { HEADIMG } from '@/assets/constant/default';

// let loopLock = false;
let btnLock = false;
export default {
  name: 'login',
  components: {},
  data() {
    return {
      loginForm: {
        phone: '',
        password: '',
      },
      registerForm: {
        phone: '',
        password: '',
        code: '',
      },
      registerRules: {
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loginRules: {
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },

      lrType: 2, // 0:二维码登录 1: 手机号登录 2: 注册 3: 重置密码
      openId: '',
      ext: '',
      codeId: '',
      phone: '',
      password: '',
      inviteCode: '',
      valiCode: '',
      qrcodePath: '',
      isCd: false,
      cd: 60,
    };
  },
  created() {
    btnLock = false;
  },
  beforeMount() {
    console.log(this.$route);
    const { query } = this.$route;
    if (query.t) {
      this.lrType = Number(query.t);
    }
  },
  beforeDestroy() { },
  methods: {
    changeTab(index) {
      this.lrType = index;
    },
    handleLogin() {
      const { phone, password } = this.loginForm;
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!password) {
        this.$message.error('请输入密码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      inputLogin({
        phone,
        password,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.token) {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/cHome`;
            this.$store.commit('updateUserInfo', {
              userName: res.emcee_name,
              headImg: res.avator,
              phone: res.phonenumber,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, '*');
            }
          } else if (res.code === '500') {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    getValidate(pSource) {
      const { isCd, cd } = this;
      const { phone } = this.registerForm;
      if (isCd) {
        this.$message.error(`${cd}秒后重新可获得验证码`);
        return;
      }
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      sendCode({
        phone,
        source: pSource,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '200') {
            this.ext = res.data.ext;
            this.codeId = res.data.codeId;
            this.$message({
              message: res.msg,
              type: 'success',
            });
            if (!isCd) {
              this.isCd = true;
              this.cd = 60;
              this.countDown();
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    countDown() {
      if (this.cd === 0) {
        this.isCd = false;
        return;
      }
      this.cd -= 1;
      timeoutTask(() => {
        this.countDown();
      }, 1000);
    },
    changePwd() {
      if (!this.phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!this.valiCode) {
        this.$message.error('请输入短信验证码!');
        return;
      }
      if (!this.password) {
        this.$message.error('请输入新密码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      resetPassword({
        phone: this.phone,
        password: this.password,
        codeId: this.codeId,
        ext: this.ext,
        valiCode: this.valiCode,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '200') {
            this.handleLogin();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    handleRegister() {
      window.open('https://www5c1.53kf.com/webCompany.php?kf_sign=jE5MzMTcwNcyODEyMTI3NjU2MjAxMDAxNzI4NTA1MDE=&arg=10850501&style=2&guest_id=12127615758001&language=cn&charset=GBK&referer=http%3A%2F%2Fdev.joymew.com%2F%23%2F&keyword=http%3A%2F%2Fdev.joymew.com%2F&tpl=crystal_blue&uid=1c4c453e113172af2559378dac88dbb0&is_group=&tfrom=1', '_blank', 'height=600,width=800,top=233.5,left=453.5,status=yes,toolbar=no,menubar=no,resizable=no,scrollbars=no,location=no,titlebar=no');
      return;
      const { phone, code, password } = this.registerForm;
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!password) {
        this.$message.error('请输入密码!');
        return;
      }
      // if (!code) {
      //   this.$message.error('请输入邀请码!');
      //   return;
      // }
      if (!code) {
        this.$message.error('请输入短信验证码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      register({
        phone,
        password,
        inviteCode: 'B5555',
        valiCode: code,
        ext: this.ext,
        codeId: this.codeId,
        openId: this.openId,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '500' || res.code === '400') {
            this.$message.error(res.errMsg);
          } else if (res.code === '200') {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/cHome`;
            this.$store.commit('updateUserInfo', {
              userName: res.user_name,
              headImg: res.headimg_url || HEADIMG,
              phone: this.phone,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, '*');
            }
          } else {
            this.$message.error('注册失败!');
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('https://static.livemew.com/livemew/home/bg.jpg');
  overflow: hidden;

  .loginDesc {
    width: 648px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .logo {
      height: 48px;
    }

    .text {
      margin-left: 55px;
      color: #000000;
      letter-spacing: 10px;
      font-size: 28px;
      font-weight: 500;
    }
  }

  .loginBox {
    width: 648px;
    height: 645px;
    background: #f7f9fc;
    border-radius: 16px;
    padding: 49px 40px;
    box-shadow: 0px 4px 13px 0px rgba(217, 227, 244, 0.25);

    .tab-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .line {
        width: 2px;
        height: 23px;
        background: #d9d9d9;
        border-radius: 7px;
        margin: 0 20px;
      }

      .tab {
        width: 80px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.25);
        font-size: 28px;

        &.active {
          font-size: 32px;
          color: #000;
        }
      }
    }

    .tip-input {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text {
        font-size: 16px;
      }
    }

    .name-input {
      margin-top: 65px;

      .pre {
        position: relative;

        &::after {
          content: '';
          width: 2px;
          height: 18px;
          background: #d9d9d9;
          border-radius: 7px;
          display: block;
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .el-input::v-deep {
        .el-input__inner {
          padding-left: 120px;
          padding-left: 150px;
          border-left: none;
          border-top: none;
          border-right: none;
          background: transparent;
          border-radius: 0;
          border-width: 2px;
          border-color: #d9d9d9;

          &.is-error {
            border-color: #f56c6c;
          }
        }

        border: none;
      }
    }

    .password-input {
      margin-top: 65px;

      .el-input::v-deep {
        .el-input__inner {
          // padding-left: 120px;
          border-left: none;
          border-top: none;
          border-right: none;
          background: transparent;
          border-radius: 0;
          border-width: 2px;
          border-color: #d9d9d9;

          &.is-error {
            border-color: #f56c6c;
          }
        }

        border: none;
      }
    }

    .code-input {
      margin-top: 65px;

      .pre {
        position: relative;

        &::after {
          content: '';
          width: 2px;
          height: 18px;
          background: #d9d9d9;
          border-radius: 7px;
          display: block;
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .el-input::v-deep {
        .el-input__inner {
          border-left: none;
          border-top: none;
          border-right: none;
          background: transparent;
          border-radius: 0;
          border-width: 2px;
          border-color: #d9d9d9;

          &.is-error {
            border-color: #f56c6c;
          }
        }

        border: none;
      }
    }

    .know {
      margin-top: 50px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text {
        font-size: 16px;
      }
    }

    .footer {
      text-align: center;
      margin-top: 10px;
    }

    .submit {
      margin-top: 0px;
      width: 520px;
      height: 66px;
      background: #167bed;
      border-radius: 12px;
    }
  }
}
</style>
<style lang="less" scoped>
.lrBox {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;

  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .box {
    width: 839px;
    height: 661px;
    display: flex;
    position: relative;
    transform: scale(0.9);

    .closeIcon {
      width: 32px;
      height: 32px;
      position: absolute;
      right: -40px;
      top: -40px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .qrIcon {
      width: 93px;
      height: 78px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .slogan {
      width: 374px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .qrcodeForm {
      width: 465px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;

      .welcome {
        font-size: 48px;
        font-weight: bold;
        color: #373742;
        position: relative;
        margin-top: 40px;

        &::before {
          content: '';
          display: block;
          width: 66px;
          height: 2px;
          background: linear-gradient(139deg, #fec84e 0%, #feaf40 100%);
          position: absolute;
          left: -77px;
          top: 32px;
        }

        &::after {
          content: '';
          display: block;
          width: 66px;
          height: 2px;
          background: linear-gradient(139deg, #fec84e 0%, #feaf40 100%);
          position: absolute;
          right: -77px;
          top: 32px;
        }
      }

      .tit {
        font-weight: 500;
        color: #373742;
        font-size: 24px;
        margin-top: 48px;
      }

      .qrcodeBox {
        width: 289px;
        height: 289px;
        border-radius: 4px;
        border: 1px solid #feb342;
        margin-top: 28px;

        .el-icon-loading {
          font-size: 42px;
          color: #feb342;
        }

        img {
          width: 265px;
          height: 265px;
        }
      }

      .tip {
        color: #83838b;
        font-weight: 400;
        font-size: 24px;
        margin-top: 23px;
      }

      .otherLoginEntry {
        font-size: 18px;
        font-weight: 400;
        color: #83838b;
        margin-top: 28px;
        cursor: pointer;

        &:hover {
          color: #febd48;
        }
      }

      .loginTip {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;

        label {
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          color: #febd48;
        }
      }
    }

    .loginForm {
      width: 465px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 100px 48px 0 48px;

      .chooseType {
        width: 100%;

        .item {
          font-weight: 500;
          color: #969696;
          font-size: 24px;
          cursor: pointer;

          &.active {
            font-weight: 500;
            color: #373742;
            margin-left: 40px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .phoneInput {
        margin-top: 50px;
        width: 100%;
      }

      .passwordInput {
        margin-top: 45px;
        width: 100%;
      }

      .el-input::v-deep {
        font-size: 18px;

        .el-input__inner {
          height: 50px;

          &:focus {
            border-color: #febd48;
          }
        }
      }

      .forgetPwdEntry {
        width: 100%;
        margin-top: 30px;
        font-weight: 400;
        color: #feb041;
        font-size: 18px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .loginBtn {
        width: 314px;
        height: 68px;
        background-image: url('https://static.livemew.com/livemew/image/button.png');
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 24px;
        margin-top: 55px;
        padding-bottom: 12px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 16px;
        width: 100%;
        margin-top: 17px;
        cursor: pointer;

        &:hover {
          color: #febd48;
        }
      }

      .loginTip {
        width: 100%;
        margin-top: 59px;
        font-size: 16px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;

        label {
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          color: #febd48;
        }
      }
    }

    .registerForm {
      width: 465px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 100px 48px 0 48px;

      .chooseType {
        width: 100%;

        .item {
          font-weight: 500;
          color: #969696;
          font-size: 24px;
          cursor: pointer;

          &.active {
            font-weight: 500;
            color: #373742;
            margin-right: 40px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .phoneInput {
        margin-top: 50px;
        width: 100%;
      }

      .passwordInput {
        margin-top: 20px;
        width: 100%;
      }

      .inviteCodeInput {
        margin-top: 20px;
        width: 100%;
      }

      .validateCodeInput {
        margin-top: 20px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        .el-input::v-deep {
          width: 200px;
        }

        .getValiCodeBtn {
          font-weight: 400;
          font-size: 18px;
          color: #feb041;
        }
      }

      .el-input::v-deep {
        font-size: 18px;

        .el-input__inner {
          height: 50px;

          &:focus {
            border-color: #febd48;
          }
        }
      }

      .registerBtn {
        width: 314px;
        height: 68px;
        background-image: url('https://static.livemew.com/livemew/image/button.png');
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 12px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 16px;
        width: 100%;
        margin-top: 17px;
        cursor: pointer;

        &:hover {
          color: #febd48;
        }
      }

      .registerTip {
        width: 100%;
        margin-top: 38px;
        font-size: 16px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;

        label {
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          color: #febd48;
        }
      }
    }

    .resetForm {
      width: 465px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 100px 48px 0 48px;

      .tit {
        width: 100%;
        font-weight: 500;
        color: #373742;
        font-size: 24px;
      }

      .phoneInput {
        margin-top: 50px;
        width: 100%;
      }

      .validateCodeInput {
        margin-top: 20px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        .el-input::v-deep {
          width: 200px;
        }

        .getValiCodeBtn {
          font-weight: 400;
          font-size: 18px;
          color: #feb041;
        }
      }

      .passwordInput {
        margin-top: 20px;
        width: 100%;
      }

      .el-input::v-deep {
        font-size: 18px;

        .el-input__inner {
          height: 50px;

          &:focus {
            border-color: #febd48;
          }
        }
      }

      .resetBtn {
        width: 314px;
        height: 68px;
        background-image: url('https://static.livemew.com/livemew/image/button.png');
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 12px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 16px;
        width: 100%;
        margin-top: 17px;
        cursor: pointer;

        &:hover {
          color: #febd48;
        }
      }

      .resetTip {
        width: 100%;
        margin-top: 38px;
        font-size: 16px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;

        label {
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          color: #febd48;
        }
      }
    }
  }
}</style>
